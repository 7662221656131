import React, { useEffect, useRef } from "react";
// import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ErrorLogo from "../Assets/Images/Icons/somethingWentWrongError.svg";
import { useSelector } from "react-redux";
import momentTimezone from "moment-timezone";
import imageCompression from "browser-image-compression";
import dayjs from "dayjs";
//Sentry
import * as Sentry from "@sentry/react";

/** Environment */
export const IS_DEV = 2; // For local set it to 0, for dev-server set it to 1
export let API_URL;
export let API_KEY;
export let BUCKET_URL;
export let PROFILE_URL;
export let STATIC_PAGE_URL;
export const API_VERSION = `v1`;
export const alias = IS_DEV === 2 ? "" : "/kriya-universe";
export let QUOTE_SAMPLE_CSV;

/** User Roles */
export const ROLE = {
	ADMIN: "admin",
	USER: "user",
};

switch (IS_DEV) {
	case 0:
		API_URL = `http://localhost:8049/api/${API_VERSION}`;
		API_KEY =
			"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYWRtaW4iLCJzZXJ2ZXJFbnYiOiJsb2NhbCIsImNyZWF0ZWRfYXQiOiIyMDI0LTA0LTI2VDExOjE3OjEyLjQ3MloiLCJpYXQiOjE3MTQxMzAyMzIsImF1ZCI6ImFkbWluIn0.R1oPGMdRalSpd7kyGxylOLt0pEfGK1L09tW0qIt_h4Q";
		BUCKET_URL = "https://s3.ap-south-1.amazonaws.com/kriya-universe";
		PROFILE_URL = BUCKET_URL + "/profile";
		STATIC_PAGE_URL = "http://localhost:3000/kriya-universe/";
		QUOTE_SAMPLE_CSV = "https://usc1.contabostorage.com/76d7974a044f4d9ea80894beffbc741c:kriyauniverse-dev/bulk-insert/quote/quote_bulk_sample.csv";
		break;
	case 1:
		API_URL = `https://dev.3rddigital.com/kriyauniverse-api/api/${API_VERSION}`;
		API_KEY =
			"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYWRtaW4iLCJzZXJ2ZXJFbnYiOiJkZXYiLCJjcmVhdGVkX2F0IjoiMjAyNC0wNC0yNlQxMToxNzoxMi40NzVaIiwiaWF0IjoxNzE0MTMwMjMyLCJhdWQiOiJhZG1pbiJ9.cutIi0e-HmKMMaHE_GwQtzzqRebhGYUdC1sfDXF5ADs";
		BUCKET_URL = "https://s3.ap-south-1.amazonaws.com/kriya-universe";
		PROFILE_URL = BUCKET_URL + "/profile";
		STATIC_PAGE_URL = "https://dev.3rddigital.com/kriya-universe/";
		QUOTE_SAMPLE_CSV = "https://usc1.contabostorage.com/76d7974a044f4d9ea80894beffbc741c:kriyauniverse-dev/bulk-insert/quote/quote_bulk_sample.csv";

		break;
	case 2:
		API_URL = `https://app.kriyaverse.com/api/${API_VERSION}`;
		API_KEY =
			"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYWRtaW4iLCJzZXJ2ZXJFbnYiOiJwcm9kIiwiY3JlYXRlZF9hdCI6IjIwMjQtMDUtMDFUMDY6NTU6MjAuMzc0WiIsImlhdCI6MTcxNDU0NjUyMCwiYXVkIjoiYWRtaW4ifQ.p5l8CMjrCJv0pOjF-HMX5zRYX_B4LcFs_hq98YpRre0";
		BUCKET_URL = "https://s3.ap-south-1.amazonaws.com/kriya-universe";
		PROFILE_URL = BUCKET_URL + "/profile";
		STATIC_PAGE_URL = "https://app.kriyaverse.com/";
		QUOTE_SAMPLE_CSV = "https://usc1.contabostorage.com/76d7974a044f4d9ea80894beffbc741c:kriyauniverse-live/bulk-insert/quote/quote_bulk_sample.csv";

		break;
	default:
}

/** Common Variables */
export const EMAIL_REGEX = /^[\w+-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
export const PHONE_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const ONLY_NUMBERS_REGEX = /^[0-9]+$/;
export const ONLY_ALPHABETS_REGEX = /^[A-Za-z ]+$/;

/** Date Functions Starts */
export const dayjsFormat = "MM-DD-YYYY";
export const lifetimeDate = new Date(dayjs("01-01-2024").format(dayjsFormat));
const day = new Date(dayjs("01-01-2024").format(dayjsFormat)).getDate();
const month = new Date(dayjs("01-01-2024").format(dayjsFormat)).getMonth() + 1;
const year = new Date(dayjs("01-01-2024").format(dayjsFormat)).getFullYear();
export const lifetimeDateWithFormat = `${month < 9 ? `0${month}` : month}-${day < 9 ? `0${day}` : day}-${year}`;
/** Date Functions Ends */

/** Reset Function to remove data after login */
export const Reset = () => {
	localStorage.removeItem("userAccessToken");
};

/** Error Handling Functions Starts */
export const dispatchLoading = (dispatch, scope, status) => dispatch({ type: "SET_LOADING", payload: { scope, status } });

export const dispatchToast = (dispatch, scope, status) => dispatch({ type: "SET_TOAST", payload: { scope, status } });

export const dispatchError = (dispatch, scope, status) => dispatch({ type: "SET_ERROR", payload: { scope, status } });

export const catchHandler = (dispatch, scope) => (err) => {
	console.log("Err", err);
	let errMsg = err?.response?.data?.msg
		? err?.response?.data?.msg
		: err?.response?.data?.message
		? err?.response?.data?.message
		: err?.response?.data?.error
		? err?.response?.data?.error
		: "Something went wrong";
	dispatchToast(dispatch, "error", errMsg);
	if (err.code === "ERR_NETWORK") dispatchToast(dispatch, "error", "Unable to connect to server");
	else if (err?.response?.status !== 401) {
		dispatchError(dispatch, scope, [errMsg]);
	} else if (err?.response?.status === 401) {
		localStorage.setItem("isLoggedIn", false);
		dispatchError(dispatch, scope, [errMsg]);
		// window.location.assign(`${CLIENT_URL}/auth/login`);
	} else dispatchToast(dispatch, "error", errMsg);
	dispatchLoading(dispatch, scope, false);
	dispatchError(dispatch, scope, [errMsg]);
};

export const elseHandler = (dispatch, scope, data) => {
	dispatchToast(dispatch, "error", data?.message);
	dispatchError(dispatch, scope, data?.error);
};

export const ErrorFallbackPage = ({ error, resetErrorBoundary }) => {
	// Call resetErrorBoundary() to reset the error boundary and retry the render.

	// Use Navigate
	const navigate = useNavigate();

	// Use Translation
	const { t } = useTranslation();

	// Use location
	const { pathname } = useLocation();

	// Use Ref
	const originalPathname = useRef(pathname);

	// Goto previous Page
	const goToBack = () => {
		navigate(-1);
	};

	// Use Effect
	useEffect(() => {
		if (pathname !== originalPathname.current) resetErrorBoundary();
	}, [pathname, resetErrorBoundary]);

	useEffect(() => {
		console.log("Error ==> ", error);
		if (error && IS_DEV === 2) {
			console.log("==>", error);
			Sentry.init({
				dsn: "https://c43235e3f45bf9f32b724b5bd1e4c172@o4507175060242432.ingest.us.sentry.io/4507650435907584",
				integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
				// Performance Monitoring
				tracesSampleRate: 1.0, //  Capture 100% of the transactions
				// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
				// Session Replay
				replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
				replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
			});
			const logging = Sentry.captureException(error);
			console.log("logging ==>", logging);
		}
	}, [error]);

	return (
		<div className="text-center w-100 d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
			<div>
				<div className="px-3 mb-4 header-logo">
					<img src={ErrorLogo} alt="header logo" className="img-fluid" onClick={(e) => navigate("/")} />
				</div>
				<h5 className="ff_rg mb-1 f18">{t("error.somethingWrong")}</h5>
				<p className="ff_rg f14">
					{t("error.thereWasProblem")}
					<span className="d-block ff_rg f14 mx-3">{t("error.pleaseRefresh")}</span>
				</p>
				<p className="ff_rg f14 mt-3 text-secondary">
					{t("error.somethingNot")} ?{" "}
					<span className="ff_md f14 text-dark">
						{t("error.contact")} :{" "}
						<Link className="ff_md f14 text-dark" to="mailto: support@companyname.com">
							support@companyname.com
						</Link>{" "}
					</span>
				</p>
				<button className="btn btn-primary text-uppercase fs-6 px-3 txt-white mt-3" onClick={() => goToBack()}>
					{t("error.goBack")}
				</button>
			</div>
		</div>
	);
};
/** Error Handling Functions Ends */

/**
 * Protected Route for Admin
 * @param {Object} routeComponent - Object with route data
 * @param {String} routeComponent.roleName - Role of user
 * @param {Function} routeComponent.children - Child component / page
 * @returns {Function} Child Component
 */
export const ProtectedRouteAdmin = ({ roleName, children }) => {
	const { role, isLoggedIn } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	if (isLoggedIn === true && role === roleName) return <React.Fragment>{children}</React.Fragment>;
	else navigate(`/auth/login`, { replace: true });
};

/** Loader */
export const SiteLoader = ({ text }) => {
	const { pathname } = useLocation();
	const { t } = useTranslation();
	if (pathname === "/") {
		return null;
	}
	return (
		<div
			style={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				textAlign: "center",
				width: "calc(100% - 1rem)",
			}}
		>
			<div className="Loader">
				<div className="d-block">
					<div className="spinner-border" role="status"></div>
				</div>
			</div>
			<p style={{ fontSize: "1.5rem", lineHeight: "1.1" }}>{text !== undefined ? text : t("pleaseWait")}</p>
		</div>
	);
};

/** Global Button Loader */
export const BtnLoader = () => {
	return (
		<div className="d-block">
			<div className="spinner-border" role="status"></div>
		</div>
	);
};

//Get Local Timezone:
const split = new Date().toString().split(" ");
const timeZone = split.slice(-3).join(" ");
// Remove brackets
const withoutBrackets = timeZone.replace(/\(|\)/g, "");
// Extract first characters of each word and join them
export const finalTimeZone = withoutBrackets
	.split(" ")
	.map((word) => word.charAt(0))
	.join("");

export const getCurrentTimeZone = () => {
	const currentTimeZone = momentTimezone.tz.guess();
	return currentTimeZone;
};

/** Function to compress image before API call */
export const imageCompressorFn = async (file, setState) => {
	const options = {
		maxSizeMB: 1,
		maxWidthOrHeight: 1920,
		useWebWorker: true,
	};
	try {
		const compFile = await imageCompression(file, options);
		setState(compFile);
	} catch (error) {
		setState(file);
	}
};

//Common Labels and Counts for different kriya details
export const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const currentYear = new Date().getFullYear();
export const counts = [31, currentYear % 4 === 0 && (currentYear % 100 !== 0 || currentYear % 400 === 0) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export const formatDate = (timestamp) => {
	const date = new Date(timestamp);
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");
	return `${month}-${day}-${year}`;
};

export const subscriptionTypeMapping = {
	"free trial": { color: "orange", label: "Free Trial" },
	paid: { color: "green", label: "Paid" },
	expired: { color: "red", label: "Expired" },
	cancel: { color: "red", label: "Canceled" },
};

//Options for Chart
export const options = [
	{ title: "Last 12 Month", value: 12 },
	{ title: "This Month", value: "current" },
	{ title: "Last 6 Month", value: 6 },
	{ title: "Last 1 Month", value: 1 },
	{ title: "Last 7 Days", value: 7 },
];

export function convertUTCtoLocalDateTime(utcDateTimeString) {
	const utcDate = new Date(utcDateTimeString);

	const localYear = utcDate.getFullYear();
	const localMonth = (utcDate.getMonth() + 1).toString().padStart(2, "0"); // Month starts from 0, so adding 1 to adjust
	const localDay = utcDate.getDate().toString().padStart(2, "0");

	const formattedDate = `${localMonth}-${localDay}-${localYear}`;

	const localHours = utcDate.getHours().toString().padStart(2, "0");
	const localMinutes = utcDate.getMinutes().toString().padStart(2, "0");
	const localSeconds = utcDate.getSeconds().toString().padStart(2, "0");

	const formattedTime = `${localHours}:${localMinutes}:${localSeconds}`;

	return formattedDate + " " + formattedTime;
}

export function millisecondsToMinutesAndSeconds(totalMilliseconds) {
	let totalSeconds = totalMilliseconds / 1000;

	// Calculate minutes and remaining seconds
	let minutes = totalSeconds / 60;
	let seconds = totalSeconds % 60;

	return { minutes: minutes, seconds: seconds };
}

export function formatTime(minutes, seconds) {
	var formattedTime = "";
	if (minutes > 1) {
		formattedTime += minutes.toFixed(2) + " m ";
	}
	if (seconds > 0 || minutes > 0) {
		formattedTime += seconds.toFixed(2) + " s ";
	}

	return formattedTime.trim();
}

export function convertSecondsToTime(seconds) {
	// const hours = Math.floor(seconds / 3600);
	// const minutes = Math.floor((seconds % 3600) / 60);

	// if (hours <= 0) {
	// 	return `${minutes} minutes`;
	// } else {
	// 	return `${hours} hours ${minutes} minutes`;
	// }

	const hours = seconds / 3600;
	return `${hours.toFixed(2)} hours`;
}
