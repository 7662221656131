import svg from "./svg";

export const sideMenuBarData = [
	{
		id: 1,
		path: "dashboard",
		label: "Dashboard",
		image: svg?.dashboard,
	},
	{
		id: 2,
		path: "users",
		label: "Users",
		image: svg?.users,
	},
	{
		id: 3,
		path: "retrospection",
		label: "Introspection",
		image: svg?.introspection,
	},
	{
		id: 4,
		path: "static-pages",
		label: "Static Pages",
		image: svg?.staticPage,
	},
	{
		id: 5,
		path: "subscription-history",
		label: "Subscriptions",
		image: svg?.subscription,
	},
	{
		id: 6,
		path: "quote",
		label: "Quotes",
		image: svg?.quoteLogo,
	},
	{
		id: 7,
		path: "api-history",
		label: "API calls History",
		image: svg?.apiHistory,
	},
];
