import { combineReducers, configureStore } from "@reduxjs/toolkit";

const errorReducer = (state = { authError: undefined }, action) => {
	switch (action.type) {
		case "SET_ERROR":
			const temp = {};
			const { payload } = action;

			if (payload.status && payload.status.length) {
				payload.status &&
					payload?.status.forEach((ele) => {
						if (ele.param === "_error") {
							ele.nestedErrors?.forEach((ele1) => {
								const key = ele1.param.split(".").pop();
								temp[key] = ele1.msg;
							});
						} else if (ele.param?.includes("updates.")) {
							const key = ele.param.split(".").slice(1).join(".");
							temp[key] = ele.msg;
						} else {
							temp[ele.path] = ele.msg;
						}
					});
			}

			return { ...state, [`${payload.scope}Error`]: temp };

		default:
			return state;
	}
};

const loadingReducer = (state = { authLoading: false, dashboardLoading: false }, action) => {
	switch (action.type) {
		case "SET_LOADING":
			return Object.assign({}, state, {
				[`${action.payload.scope}Loading`]: action.payload.status,
			});
		default:
			return state;
	}
};

const msgReducer = (state = { success: undefined, error: undefined }, action) => {
	switch (action.type) {
		case "SET_TOAST":
			return Object.assign({}, state, {
				[`${action.payload.scope}`]: action.payload.status || "Something went wrong!",
			});
		case "CLEAR_TOAST":
			return { ...state, error: undefined, success: undefined };
		default:
			return state;
	}
};

const authReducer = (
	state = {
		isLoggedIn: -1,
		role: -1,
		isAuthenticated: -1,
	},
	action
) => {
	switch (action.type) {
		case "AUTH_LOAD_SUCCESS":
			return {
				...state,
				isAuthenticated: action?.payload?.isAuthenticated,
				role: action?.payload?.role,
				isLoggedIn: true,
				user: action?.payload,
			};

		case "AUTH_LOAD_FAILURE":
			return {
				...state,
				isAuthenticated: false,
				isLoggedIn: false,
				role: -1,
			};

		case "AUTH_LOGIN_SUCCESS":
			return {
				...state,
				role: action.payload.role,
				isLoggedIn: true,
			};

		case "AUTH_LOGOUT_SUCCESS":
			return {
				isAuthenticated: false,
				isLoggedIn: false,
				user: undefined,
				role: -1,
			};

		case "LOGIN_SUCCESS":
			return {
				isLoggedIn: true,
				user: action.payload,
			};

		case "STATIC_LOGIN_LIST_SUCCESS":
			return {
				...state,
				loginList: action.payload,
			};
		case "LOGOUT_SUCCESS":
			return {
				isLoggedIn: false,
				role: -1,
				isAuthenticated: -1,
			};

		default:
			return state;
	}
};

const dashboardReducer = (
	state = {
		dashboardItem: undefined,
		signedUpUser: undefined,
		activeUser: undefined,
		activityChart: undefined,
		timeChart: undefined,
		dashboardCount: undefined,
	},
	action
) => {
	switch (action.type) {
		case "DASHBOARD_SUCCESS":
			return {
				...state,
				dashboardItem: action.payload,
			};
		case "GET_SIGNED_UP_USERS":
			return {
				...state,
				signedUpUser: action.payload,
			};

		case "GET_ACTIVE_USERS":
			return {
				...state,
				activeUser: action.payload,
			};
		case "GET_ACTIVITY_CHART_DATA":
			return {
				...state,
				activityChart: action.payload,
			};
		case "GET_TIME_CHART_DATA":
			return {
				...state,
				timeChart: action.payload,
			};
		case "DASHBOARD_COUNT_SUCCESS":
			return {
				...state,
				dashboardCount: action.payload,
			};

		default:
			return state;
	}
};

const userReducer = (
	state = {
		list: undefined,
		userCount: 0,
		userDetails: undefined,
		detail: undefined,
	},
	action
) => {
	switch (action.type) {
		case "USER_LIST_SUCCESS":
		case "USER_SEARCH_SUCCESS":
			return {
				...state,
				list: action.payload.list,
				userCount: action.payload.count,
			};
		case "FETCH_USER_DETAILS":
			return {
				...state,
				userDetails: action.payload,
			};
		case "CLEAR_USER_DETAILS":
			return {
				...state,
				userDetails: undefined,
			};
		case "USER_TOGGLE_SUCCESS":
			return {
				...state,
				userDetails: { ...state?.userDetails, isActive: !state?.userDetails?.isActive },
			};
		case "USER_SEARCH_CLEAR":
			return {
				...state,
				list: undefined,
				userCount: 0,
			};
		default:
			return state;
	}
};

const subscriptionHistoryReducer = (
	state = {
		list: undefined,
		subscriptionCount: 0,
	},
	action
) => {
	switch (action.type) {
		case "SUBSCRIPTION_LIST_SUCCESS":
		case "SUBSCRIPTION_SEARCH_SUCCESS":
			return {
				...state,
				list: action.payload.list,
				subscriptionCount: action.payload.count,
			};
		case "SUBSCRIPTION_SEARCH_CLEAR":
			return {
				...state,
				list: undefined,
				subscriptionCount: 0,
			};
		default:
			return state;
	}
};

const retrospectionReducer = (
	state = {
		overallData: undefined,
		morningRoutine: undefined,
		eveningRoutine: undefined,
		dayRoutine: undefined,
		overAllTimeChartData: undefined,
		morningRoutineChartData: undefined,
		eveningRoutineChartData: undefined,
		dayRoutineChartData: undefined,
	},
	action
) => {
	switch (action.type) {
		case "GET_ALL_ROUTINE_DATA":
			return {
				...state,
				overallData: action.payload,
			};
		case "GET_MORNING_ROUTINE_DATA":
			return {
				...state,
				morningRoutine: action.payload,
			};

		case "GET_DAY_ROUTINE_DATA":
			return {
				...state,
				dayRoutine: action.payload,
			};
		case "GET_EVENING_ROUTINE_DATA":
			return {
				...state,
				eveningRoutine: action.payload,
			};
		case "GET_OVERALL_TIME_CHART_DATA":
			return {
				...state,
				overAllTimeChartData: action.payload,
			};
		case "GET_MORNING_TIME_CHART_DATA":
			return {
				...state,
				morningRoutineChartData: action.payload,
			};
		case "GET_EVENING_TIME_CHART_DATA":
			return {
				...state,
				eveningRoutineChartData: action.payload,
			};
		case "GET_DAY_TIME_CHART_DATA":
			return {
				...state,
				dayRoutineChartData: action.payload,
			};
		default:
			return state;
	}
};

const activityReducer = (
	state = {
		activity: undefined,
	},
	action
) => {
	switch (action.type) {
		case "GET_ACTIVITY_BY_ID":
			return {
				...state,
				activity: action.payload,
			};
		default:
			return state;
	}
};

const staticReducer = (
	state = {
		faqList: undefined,
		staticPageList: undefined,
		staticPageCount: undefined,
		staticPageDetails: undefined,
		singleStaticPage: undefined,
	},
	action
) => {
	switch (action.type) {
		case "STATIC_PAGE_LIST_SUCCESS":
			return {
				...state,
				staticPageList: action.payload.list,
				staticPageCount: action.payload.count,
			};
		case "STATIC_PAGE_DETAILS_SUCCESS":
			return {
				...state,
				staticPageDetails: action.payload,
			};
		case "CLEAR_STATIC_PAGE_DETAILS":
			return {
				...state,
				staticPageDetails: undefined,
			};
		case "SINGLE_STATIC_PAGE_SUCCESS":
			return {
				...state,
				singleStaticPage: action.payload,
			};
		case "FAQ_LIST_SUCCESS":
			return {
				...state,
				faqList: action.payload,
			};
		default:
			return state;
	}
};

const apiCallsHistoryReducer = (
	state = {
		list: undefined,
		count: 0,
		success: 0,
		failed: 0,
		apiDetails: undefined,
	},
	action
) => {
	switch (action.type) {
		case "API_HISTORY_SUCCESS":
			return {
				...state,
				list: action.payload.list,
				count: action.payload.count,
				success: action.payload.successCount,
				failed: action.payload.failedCount,
			};
		case "FETCH_API_DETAILS":
			return {
				...state,
				apiDetails: action.payload,
			};
		case "CLEAR_API_DETAILS":
			return {
				...state,
				apiDetails: undefined,
			};
		default:
			return state;
	}
};

const quoteReducer = (
	state = {
		quoteList: undefined,
		quoteCount: 0,
		userDetails: undefined,
		detail: undefined,
	},
	action
) => {
	switch (action.type) {
		case "QUOTE_LIST_SUCCESS": {
			return {
				...state,
				quoteList: action.payload.quoteList,
				quoteCount: action.payload.quoteCount,
			};
		}
		case "QUOTE_SEARCH_SUCCESS":
			return {
				...state,
				quoteList: action.payload.list,
				quoteCount: action.payload.count,
			};
		case "FETCH_QUOTE_DETAILS":
			return {
				...state,
				quoteDetails: action.payload,
			};
		case "CLEAR_QUOTE_DETAILS":
			return {
				...state,
				quoteDetails: undefined,
			};
		case "QUOTE_TOGGLE_SUCCESS":
			return {
				...state,
				quoteDetails: { ...state?.quoteDetails, isActive: !state?.quoteDetails?.isActive },
			};
		case "QUOTE_SEARCH_CLEAR":
			return {
				...state,
				quoteList: undefined,
				quoteCount: 0,
			};
		default:
			return state;
	}
};
const store = configureStore({
	reducer: combineReducers({
		error: errorReducer,
		loading: loadingReducer,
		msg: msgReducer,
		auth: authReducer,
		dashboard: dashboardReducer,
		user: userReducer,
		retrospection: retrospectionReducer,
		activity: activityReducer,
		static: staticReducer,
		subscription: subscriptionHistoryReducer,
		apiCallsHistory: apiCallsHistoryReducer,
		quote: quoteReducer,
	}),
	// middleware: (getDefaultMiddleware) =>
	// 	getDefaultMiddleware({
	// 		immutableCheck: false,
	// 		serializableCheck: false,
	// 	}),
});

export default store;
