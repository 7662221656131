import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import store from "./store";
import { App as AppWrapper } from "antd";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ErrorFallbackPage, SiteLoader, alias, liveAlias } from "./helpers/utils";
import { ErrorBoundary } from "react-error-boundary";

// ToastHandler
import ToastHandler from "./helpers/ToastHandler";

// Translation
import "./helpers/i18n";

// CSS
import "./Assets/CSS/custom.css";
import "./Assets/CSS/style.css";

// Auth CSS
import "./Auth/CSS/style.css";

// Component CSS
import "./Components/CSS/style.css";

// Admin CSS
import "./Admin/CSS/style.css";

// View CSS
import "./View/CSS/style.css";

// Swiper Js
import "swiper/css";
import "swiper/css/navigation";
import { sideMenuBarData } from "./Assets/navigation";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Auth Pages
const AuthIndex = lazy(() => import("./Auth/AuthIndex"));
const Login = lazy(() => import("./Auth/Pages/Login"));
// const SignUp = lazy(() => import("./Auth/Pages/SignUp"));
const ForgotPassword = lazy(() => import("./Auth/Pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./Auth/Pages/ResetPassword"));

//Admin Pages
const AdminIndex = lazy(() => import("./Admin/AdminIndex"));
const Dashboard = lazy(() => import("./Admin/Views/Dashboard"));
const Users = lazy(() => import("./Admin/Views/Users"));
const RetroSpection = lazy(() => import("./Admin/Views/Retrospection"));
const StaticPages = lazy(() => import("./Admin/Views/StaticPages"));
const UserDetails = lazy(() => import("./Admin/Views/UserDetails"));

const Quotes = lazy(() => import("./Admin/Views/Quote"));
// import Quotes from "./Admin/Views/Quote";

const EditStaticPage = lazy(() => import("./Admin/Views/EditStaticPage"));
const Profile = lazy(() => import("./Admin/Views/Profile"));
const ChangePassword = lazy(() => import("./Admin/Views/ChangePassword"));
const ExerciseDetails = lazy(() => import("./Admin/Views/ExerciseDetails"));
const MahaMudraDetails = lazy(() => import("./Admin/Views/MahaMudraDetails"));
// const ChantDetails = lazy(() => import("./Admin/Views/ChantDetails"));
const HongSauDetails = lazy(() => import("./Admin/Views/HongSauDetails"));
const AumDetails = lazy(() => import("./Admin/Views/AumDetails"));
const KriyaDetails = lazy(() => import("./Admin/Views/KriyaDetails"));
const KriyaCountDetails = lazy(() => import("./Admin/Views/KriyaCountDetails"));
const ReadGoodDetails = lazy(() => import("./Admin/Views/ReadGoodDetails"));
const ReadSRFDetails = lazy(() => import("./Admin/Views/ReadSRFDetails"));
const PhysicalExerciseDetails = lazy(() => import("./Admin/Views/PhysicalExerciseDetails"));
const SubscriptionHistory = lazy(() => import("./Admin/Views/SubscriptionHistory"));
const ApiHistory = lazy(() => import("./Admin/Views/ApiHistory"));
const ApiHistoryDetails = lazy(() => import("./Admin/Views/ApiHistoryDetails"));
const Privacy = lazy(() => import("./Admin/Views/Privacy"));
const DataDeletion = lazy(() => import("./Admin/Views/DataDeletion"));
const Terms = lazy(() => import("./Admin/Views/TermsAndConditions"));
const AboutUs = lazy(() => import("./Admin/Views/AboutUs"));
const ContactUs = lazy(() => import("./Admin/Views/ContactUs"));
/** Error Page */
const PageNotFound = lazy(() => import("./Components/Pages/Error"));

root.render(
	<React.StrictMode>
		<AppWrapper>
			<Provider store={store}>
				{/* live */}
				{/* <BrowserRouter> */}
				{/* dev */}
				<BrowserRouter basename={alias}>
					<Suspense fallback={<SiteLoader />}>
						<ErrorBoundary FallbackComponent={ErrorFallbackPage}>
							{/* Route List */}
							<Routes>
								{/* Auth */}
								<Route path="/" element={<Login />} />
								<Route path="/auth" element={<AuthIndex />}>
									<Route path="login" element={<Login />} />
									{/* <Route path="sign-up" element={<SignUp />} />
                  <Route path="sign-up/:role" element={<SignUp />} />  */}
									<Route path="forgot-password" element={<ForgotPassword />} />
									<Route path="forgot-password/:token" element={<ResetPassword />} />
								</Route>
								{/* Auth */}

								{/* Admin */}
								<Route path="/admin" element={<AdminIndex roleName={"admin"} navigationData={sideMenuBarData} />}>
									<Route path="dashboard" element={<Dashboard />} />
									<Route path="users">
										<Route path="" element={<Users />} />
										<Route path="user-details/:userId" element={<UserDetails />} />
									</Route>
									<Route path="quote">
										<Route path="" element={<Quotes />} />
										{/* <Route path="user-details/:userId" element={<UserDetails />} /> */}
									</Route>

									<Route path="retrospection">
										<Route path="" element={<RetroSpection />} />
										<Route path="exercise-details/:activityId" element={<ExerciseDetails />} />
										<Route path="mahaMudra-details/:activityId" element={<MahaMudraDetails />} />
										{/* <Route path="chant-details/:activityId" element={<ChantDetails />} /> */}
										<Route path="hongSau-details/:activityId" element={<HongSauDetails />} />
										<Route path="aum-details/:activityId" element={<AumDetails />} />
										<Route path="kriya-details/:activityId" element={<KriyaDetails />} />
										<Route path="kriyaCount-details/:activityId" element={<KriyaCountDetails />} />
										<Route path="read-good-details/:activityId" element={<ReadGoodDetails />} />
										<Route path="read-srf-details/:activityId" element={<ReadSRFDetails />} />
										<Route path="physical-exercise-details/:activityId" element={<PhysicalExerciseDetails />} />
									</Route>
									<Route path="static-pages">
										<Route path="" element={<StaticPages />} />
										<Route exact path="edit-staticpage/:id" element={<EditStaticPage />} />
									</Route>
									<Route path="subscription-history" element={<SubscriptionHistory />} />
									<Route path="api-history">
										<Route path="" element={<ApiHistory />} />
										<Route path="details/:id" element={<ApiHistoryDetails />} />
									</Route>
									<Route path="profile" element={<Profile />} />
									<Route path="change-password" element={<ChangePassword />} />
									<Route path="*" element={<PageNotFound />} />
								</Route>
								{/* Admin */}
								<Route path="privacy-policy" element={<Privacy />} />
								<Route path="data-deletion-content" element={<DataDeletion />} />
								<Route path="terms-and-conditions" element={<Terms />} />
								<Route path="about-us" element={<AboutUs />} />
								<Route path="contact-us" element={<ContactUs />} />
								<Route path="*" element={<PageNotFound />} />
							</Routes>
							{/* Route List End */}
						</ErrorBoundary>
					</Suspense>
				</BrowserRouter>
				<ToastHandler />
			</Provider>
		</AppWrapper>
	</React.StrictMode>
);
